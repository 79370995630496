
export default {
	data() {
		return {

			buttons: [
				{
					color_background: '#E6DCC7',
					color_font: '#000',
					count: 0,
					color_string: 'Cream',
					color_code: 'RH31',
					hex: 'E6DCC7'
				},
				{
					color_background: '#E8C399',
					color_font: '#000',
					count: 0,
					color_string: 'Beige',
					color_code: 'RH30',
					hex: 'E8C399'
				},
				{
					color_background: '#E7CE62',
					color_font: '#000',
					count: 0,
					color_string: 'Bright Yellow',
					color_code: 'RH41',
					hex: 'E7CE62'
				},
				{
					color_background: '#CF9C2E',
					color_font: '#fff',
					count: 0,
					color_string: 'Mustard Yellow',
					color_code: 'RH40',
					hex: 'CF9C2E'
				},
				{
					color_background: '#5ABBC1',
					color_font: '#000',
					count: 0,
					color_string: 'Turquoise',
					color_code: 'RH26',
					hex: '5ABBC1'
				},
				{
					color_background: '#339AC1',
					color_font: '#fff',
					count: 0,
					color_string: 'Azure',
					color_code: 'RH23',
					hex: '339AC1'
				},
				{
					color_background: '#60A3D3',
					color_font: '#fff',
					count: 0,
					color_string: 'Light Blue',
					color_code: 'RH22',
					hex: '60A3D3'
				},
				{
					color_background: '#376FAB',
					color_font: '#fff',
					count: 0,
					color_string: 'Standard Blue',
					color_code: 'RH20',
					hex: '376FAB'
				},
				{
					color_background: '#49498C',
					color_font: '#fff',
					count: 0,
					color_string: 'Purple',
					color_code: 'RH21',
					hex: '49498C'
				},
				{
					color_background: '#131519',
					color_font: '#fff',
					count: 0,
					color_string: 'Black',
					color_code: 'RH70',
					hex: '131519'
				},
				{
					color_background: '#62AF4A',
					color_font: '#fff',
					count: 0,
					color_string: 'Bright Green',
					color_code: 'RH11',
					hex: '62AF4A'
				},
				{
					color_background: '#78965E',
					color_font: '#fff',
					count: 0,
					color_string: 'Standard Green',
					color_code: 'RH10',
					hex: '78965E'
				},
				{
					color_background: '#DD4586',
					color_font: '#fff',
					count: 0,
					color_string: 'Funcky Pink',
					color_code: 'RH90',
					hex: 'DD4586'
				},
				{
					color_background: '#CC2027',
					color_font: '#fff',
					count: 0,
					color_string: 'Bright Red',
					color_code: 'RH02',
					hex: 'CC2027'
				},
				{
					color_background: '#A33128',
					color_font: '#fff',
					count: 0,
					color_string: 'Standard Red',
					color_code: 'RH01',
					hex: 'A33128'
				},
				{
					color_background: '#AD6943',
					color_font: '#fff',
					count: 0,
					color_string: 'Brown',
					color_code: 'RH32',
					hex: 'AD6943'
				},
				{
					color_background: '#C8C6C7',
					color_font: '#000',
					count: 0,
					color_string: 'Pale Gray',
					color_code: 'RH65',
					hex: 'C8C6C7'
				},
				{
					color_background: '#9A9990',
					color_font: '#fff',
					count: 0,
					color_string: 'Light Gray',
					color_code: 'RH61',
					hex: '9A9990'
				},
				{
					color_background: '#77736F',
					color_font: '#fff',
					count: 0,
					color_string: 'Dark Gray',
					color_code: 'RH60',
					hex: '77736F'
				}
			]

		}
	}
}
