<template lang="pug">

	.mixer__plugin(class="u-df-column-center-center")

		SpinnerComponent(:start="spinnerStart")

		.mixer__plugin-top(class="u-df-center")

			.mixer__plugin-top--result
				//- object.mixer__plugin-image(type="image/svg+xml" :data="result" :width="width" :height="height")
				//- 	//- img.mixer__plugin-image(:src="defaultResult")
				//- 	p Your browser does not support SVG

				.mixer__plugin-image(v-if="MIXER_RESULT") <span v-html="MIXER_RESULT"></span>
				img.mixer__plugin-image(v-if="!MIXER_RESULT" :src="defaultResult")

			.mixer__plugin-top--panel
				h3.mixer__plugin-title(class="tertiary-title") How it works?

				ul.mixer__plugin-list

					li.mixer__plugin-list-item Select your colors from the swatches below.
					li.mixer__plugin-list-item Use the + buttons to add granules and the - buttons to remove them.
					li.mixer__plugin-list-item When you have finished your design, save or print it as a PDF and <router-link class="link link--default" to="/contact">contact us</router-link> today!

				.mixer__plugin-buttons(class="u-df-between")

					button.mixer__button.mixer__button--save(class="btn btn--blue" @click="save") Save as PDF
					button.mixer__button.mixer__button--reset(class="btn btn--red" @click="reset") Reset


		.mixer__plugin-bottom(class="u-df-row-wrap-start")

			BasePluginButton.mixer__button.mixer__button--color(v-for="(button, index) in buttons" :key="index" :button="button" @increase="increase" @decrease="decrease")


</template>

<script>
import SpinnerComponent from '@/components/common/SpinnerComponent'
import Buttons from '@/mixins/Buttons.js'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'BaseColorMixerComponent',

	mixins: [ Buttons ],

	components: {
		SpinnerComponent
	},

	computed: {
		...mapGetters([ 'MIXER_RESULT', 'URL' ]),

		// result: {
		// 	get() {
		// 		return this.MIXER_RESULT ? this.MIXER_RESULT : this.defaultResult
		// 	},
		// 	set(newVal) {
		// 		this.$store.commit('SET_MIXER_RESULT', '')
		// 	}			
		// }
	},

	data() {
		return {
			defaultResult: require('@/assets/img/mixer/plugin.svg'),
			width: 840,
			height: 480,
			spinnerStart: false
		}
	},

	// watch: {
	// 	MIXER_RESULT() {
	// 		let svgElement = document.querySelector('.mixer__plugin-top--result')

	// 		console.log(svgElement)

	// 		// svgElement.setAttribute('width', `${ this.width }px`)
	// 		// svgElement.setAttribute('height', `${ this.height }px`)
	// 	}
	// },

	created() {
		window.addEventListener('resize', this.updateSize)
	},

	mounted() {
		this.$store.commit('SET_MIXER_RESULT', '')
		this.updateSize()
	},

	updated() {
		// this.updateSize()

		this.checkSvgElement()
		
	},

	// beforeDestroy() {
	// 	window.removeEventListener('resize', this.updateSize)
	// },

	methods: {
		...mapActions([ 'getFileRequest' ]),

		updateSize() {
			this.width = document.querySelector('.mixer__plugin-top--result').offsetWidth
			this.height = document.querySelector('.mixer__plugin-top--result').offsetHeight

			this.checkSvgElement()
		},

		checkSvgElement() {
			let svgElement = document.getElementById('Layer_1')

			if (svgElement) this.updateSvgElement(svgElement)
		},

		updateSvgElement(svgElement) {

			// svgElement.setAttribute('viewBox', '100 185 393 228')
			// svgElement.setAttribute('style', 'border-radius: 1rem')

			svgElement.setAttribute('width', `${ this.width }px`)
			svgElement.setAttribute('height', `${ this.height }px`)
			
			svgElement.setAttribute('viewBox', `100 185 ${ this.width / 2.137 } ${ this.height / 2.105 }`)

		},

		reset() {
			// this.result = this.defaultResult

			this.$store.commit('SET_MIXER_RESULT', '')

			this.buttons.forEach( item => item.count = 0 )
		},

		save() {
			this.getFile('pdf')
		},

		increase(code) {
			let button = this.buttons.find( item => item.color_code === code )
			button.count++

			this.getFile('svg')
		},

		decrease(code) {
			let button = this.buttons.find( item => item.color_code === code )
			button.count--

			this.getFile('svg')
		},

		getFile(type) {

			let buttonsWithColors = this.getButtonsWithColors()

			if (!buttonsWithColors.length) return

			if (type === 'pdf') {

				let link = `${ this.URL }/api/public/${ type }?`

				buttonsWithColors.forEach( item => link += `colors[]=${ item.count },${ item.hex }&` )

				window.open(link, '_blank')

			} else {
				this.spinnerStart = true

				let data = { 
					params: this.getParams(buttonsWithColors),
					type: type
				}

				this.getFileRequest(data)
					.then( () => this.spinnerStart = false )
			}
		},

		getButtonsWithColors() {
			return this.buttons.filter( item => item.count > 0 )
		},
		
		getParams(buttonsWithColors) {
			let result = []
			buttonsWithColors.forEach( item => result.push(`${ item.count },${ item.hex }`) )
			return result
		}
	}

}
</script>

<style lang="sass" scoped>
@import '~@/assets/styles/abstracts/_variables.sass'


.mixer
	
	
	&__plugin


		&-top
			align-items: stretch
			width: 100%


			@media screen and (max-width: $phone)
				flex-direction: column
			
			
			&--result
				flex-basis: 60%
				margin-right: 6rem
				max-width: 58%


				@media screen and (max-width: $phone)
					flex-basis: unset
					max-width: 99%
					order: 1
					margin-right: 0


			&--panel
				flex-basis: calc( 40% - 6rem )


				@media screen and (max-width: $phone)
					flex-basis: unset
					order: 0

					margin-bottom: 4rem


		&-image
			width: 100%
			height: 48rem

			object-fit: cover

			border-radius: 1rem
			overflow: hidden

			filter: drop-shadow(0px 5px 10px rgba($color_black, .15))


		&-title
			margin-bottom: 4rem


		&-list
			margin-bottom: 10rem
			max-width: 100%


			@media screen and (max-width: $big_desktop)
				margin-bottom: 7rem


			@media screen and (max-width: $phone)
				margin-bottom: 4rem


			&-item
				position: relative

				left: 2.1rem

				padding-right: 2.1rem


				@media screen and (max-width: $phone)
					margin-bottom: 3rem !important


				&:not(:last-child)
					margin-bottom: 4rem


		&-bottom
			width: 100%

			margin-top: 6rem


			@media screen and (max-width: $small_desktop)


				& .mixer__button
					margin-right: 1rem


			@media screen and (max-width: $phone)


				& .mixer__button
					margin-right: auto


	&__button


		&--save, &--reset
			width: 22.1rem


			@media screen and (max-width: $big_desktop)
				width: 48%


		&--color
			width: 10%
			min-width: 10.9rem

			margin-bottom: 3.4rem


			@media screen and (max-width: $phone)
				margin: 0 auto 3.4rem



</style>