import axios from 'axios'

export const contact_form = {

	state: {
		baseURL: process.env.VUE_APP_BASE_URL
	},

	actions: {

		sendFormRequest: ({ state }, data) => {

			return new Promise( (resolve, reject) => {

				const config = {
					url: `${ state.baseURL }/api/public/email`,
					method: 'POST',
					data: data,
					headers: {
						'Content-Type': 'application/json'
					}
				}

				axios(config)
					.then( response => 	resolve(response) )
					.catch( err => reject(err) )

			})
		}
	}
}