<template lang="pug">
	
	div.layout(class="u-df-column-center")
		BaseHeaderComponent.header
		BaseChildComponent
		BaseFooterComponent

</template>

<script>
	export default {
		name: 'default'
	}
</script>

<style lang="sass" scoped>

.layout
	min-height: 100vh


.header
	position: fixed
	top: 0

	z-index: 1


</style>