<template lang="pug">

	#app
		transition(name="page" mode="out-in")
			component(v-if="layout" :is="layout")

</template>

<script>
	const requireContext = require.context('./layouts', false, /.*\.vue$/)

	const layouts = requireContext.keys()
		.map(file =>
			[file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
		)
		.reduce((components, [name, component]) => {
			components[name] = component.default || component
			return components
		}, {})

	export default {

		name: 'App',

		computed: {
			routePath() {
				return this.$route.path
			}
		},

		data() {
			return {
				layout: null,
				defaultLayout: 'default'
			}
		},

		watch: {
			routePath() {
				window.scrollTo(0, -1000)
			}
		},

		methods: {
			setLayout(layout) {
				if (!layout || !layouts[layout]) {
					layout = this.defaultLayout
				}

				this.layout = layouts[layout]
			}
		}

	}
</script>

<style lang="sass">

#app
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

</style>
