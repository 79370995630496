import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VueYouTubeEmbed from 'vue-youtube-embed'

import { store } from './store'

import './assets/styles/main.sass'

Vue.use(VueAxios, axios)
Vue.use(VueYouTubeEmbed)

Vue.config.productionTip = false

const requireComponent = require.context(
	'./components/',
	true,
	/Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
	const componentConfig = requireComponent(fileName)
	const componentName = upperFirst(
		camelCase(
			fileName
				.split('/')
				.pop()
				.replace(/\.\w+$/, '')
		)
	)
	Vue.component(
		componentName,
		componentConfig.default || componentConfig
	)
})

new Vue({
	router,
	store,
	...App
}).$mount('#app')
