<template lang="pug">

	transition-group(class="full" tag="div" name="page" mode="out-in" )
		div(key="a")
			slot
				router-view

</template>

<script>
	export default {
		name: 'BaseChildComponent'
	}
</script>

<style lang="sass" scoped>

	.full
		width: 100%
		min-height: calc( 100vh - 43.8rem )

</style>
