import axios from 'axios'

export const mixer = {

	state: {
		image: '',
		baseURL: process.env.VUE_APP_BASE_URL
	},

	mutations: {
		SET_MIXER_RESULT: (state, data) => {
			state.image = data.data
		}
	},

	actions: {

		getFileRequest: ({ state, commit }, data) => {
			return new Promise( resolve => {
				const config = {
					url: `${ state.baseURL }/api/public/${ data.type }`,
					params: {
						colors: data.params
					}
				}
				axios(config)
					.then( response => {

						if (data.type === 'svg') {
							commit('SET_MIXER_RESULT', response)
						}
						
						resolve(response)
					})
					.catch( err => {
						console.log(err)
					})
			})
		}

	},

	getters: {
		MIXER_RESULT: state => state.image,
		URL: state => state.baseURL
	}
}