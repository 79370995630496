<template lang="pug">

	.button__container(class="u-df-column-center")

		.button(class="u-df-center" :style="{ 'background-color' : button.color_background, 'color' : button.color_font }")

			.button__minus(@click="decrease") -
			.button__count {{ button.count }}
			.button__plus(@click="increase") +

		.button__decription(class="u-df-column-center-around")
			.button__decription-name {{ button.color_string }}
			.button__decription-code {{ button.color_code }}

	
</template>

<script>
export default {
	name: 'BasePluginButton',

	props: {
		button: {
			type: Object,
			default() {
				return ''
			}
		}
	},

	data() {
		return {
			count: 0
		}
	},

	methods: {
		increase() {
			this.count++
			this.$emit('increase', this.button.color_code)
		},
		decrease() {
			if (this.count > 0) {
				this.count--
				this.$emit('decrease', this.button.color_code)
			}
		}
	}

}
</script>

<style lang="sass" scoped>
@import '~@/assets/styles/abstracts/_variables.sass'


.button__container
	cursor: pointer


	&:hover > .button
		box-shadow: none


.button
	width: 10.8rem
	height: 5.4rem

	border-radius: 5rem
	box-shadow: inset 0px 2px 4px rgba($color_black, .15)

	justify-content: space-evenly

	transition: all .3s
	

	&__minus, &__plus
		font-size: 2.6rem
		line-height: 1


	&__decription
		margin-top: .6rem
		font-size: $smaller_font_size


</style>