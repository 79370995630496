<template lang="pug">	
	
	footer.footer(class="u-df-center")

		.footer__container(class="container u-df-between")

			.footer__column.footer__column--contacts(class="u-df-column-flex-start")
				a.footer__column-row.footer__column-row--tel(class="link link--footer" href="tel:1-800-391-8721") 1-800-391-8721
				a.footer__column-row.footer__column-row--email(class="link link--footer" href="mailto:daniel@titussurfacing.com") daniel@titussurfacing.com

			.footer__column(class="u-df-column-flex-start")
				router-link.footer__column-row(class="link link--footer" to="/") About Us
				router-link.footer__column-row(class="link link--footer" to="/products") Products
				router-link.footer__column-row(class="link link--footer" to="/services") Services

			.footer__column(class="u-df-column-flex-start")
				router-link.footer__column-row(class="link link--footer" to="/contact") Contact Us
				router-link.footer__column-row(class="link link--footer" to="/color-mixer") Color Mixer
				router-link.footer__column-row(class="link link--footer" to="/color-blends") Color Blends

			.footer__column.footer__column--logo(class="u-df-column-flex-start")
				router-link.footer__column-row(to="/")

					img.footer__logo(src="@/assets/img/logo.svg")
		
		
</template>

<script>
export default {
	name: 'BaseFooterComponent'
}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'


.footer
	height: 43.8rem
	width: 100%

	background: url(~@/assets/img/footer_bg.svg) no-repeat center / cover


	@media screen and (max-width: $tablet)
		height: unset
		padding: 20rem 0 0


	&__container


		@media screen and (max-width: $tablet)
			flex-direction: column


	&__column


		@media screen and (max-width: $tablet)
			align-items: flex-start
			width: 100%


		&--contacts
			align-self: flex-end


			@media screen and (max-width: $tablet)
				align-self: unset
				order: 1


		&--logo
			align-self: flex-start


			@media screen and (max-width: $tablet)
				align-self: unset
				order: 2

				align-items: center


		&-row


			&--tel
				font-size: $bigger_font_size
				position: relative


				@media screen and (max-width: $tablet)
					font-size: $bigger_font_size__mobile
					left: 2.7rem


				&::before
					content: ""
					display: inline-block

					width: 2.4rem
					height: 2.4rem

					position: absolute
					top: 20%
					left: -3.1rem

					background: url(~@/assets/img/ic_phone.svg)



			&--email
				position: relative


				@media screen and (max-width: $tablet)
					font-size: $bigger_font_size__mobile
					left: 2.7rem


				&::before
					content: ""
					display: inline-block

					width: 2.4rem
					height: 2.4rem

					position: absolute
					top: .2rem
					left: -3.1rem

					background: url(~@/assets/img/ic_mail.svg)



			&:not(:last-child)
				margin-bottom: 3.3rem


			&:nth-child(n)			


				@media screen and (max-width: $tablet)
					margin-bottom: 3.3rem !important


</style>
