import Vue from 'vue'
import Vuex from 'vuex'
import { mixer } from './modules/mixer'
import { contact_form } from './modules/contact_form'

Vue.use(Vuex)

const store = new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		mixer,
		contact_form
	}
})

export { store }