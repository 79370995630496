<template lang="pug">	
	
	header.header

		.container(class="u-df-between u-h100")

			router-link.header__logo-container(class="u-df-center" to="/")

				img.header__logo(src="@/assets/img/logo.svg")


			.header__navigation-container(class="u-df-end")

				nav.header__navigation

					ul.header__navigation-list(class="u-df-around")

						li.header__navigation-list-item(v-for="(item, index) in mobileLink" :key="index")
							router-link(class="link link--default" :class="{ 'link--active' : routerPath === item.to }" :to="item.to") {{ item.text }}
		
						li.header__navigation-list-item.header__navigation-list-item--only-mobile
							.more-link(class="link link--default link--active" @click="moreIsShown = !moreIsShown") More...

					ul.header__navigation-list.header__navigation-list--only-mobile(class="u-df-between" v-if="moreIsShown && width < 995")

						li.header__navigation-list-item(v-for="(item, index) in mobileHiddenLink" :key="index")
							router-link(class="link link--default" :class="{ 'link--active' : routerPath === item.to }" :to="item.to") {{ item.text }}
		
</template>

<script>
export default {
	name: 'BaseHeaderComponent',

	computed: {
		routerPath() {
			return this.$route.path
		},
		mobileHiddenLink() {
			return this.headerLinks.filter( item => item.mobile === false )		
		},
		mobileLink() {
			if (this.width < 995) {
				return this.headerLinks.filter( item => item.mobile === true )
			} else return this.headerLinks	
		}
	},

	data() {
		return {
			headerLinks: [
				{
					text: 'About Us',
					to: '/',
					mobile: true
				},
				{
					text: 'Products',
					to: '/products',
					mobile: true
				},
				{
					text: 'Services',
					to: '/services',
					mobile: true
				},
				{
					text: 'Contact Us',
					to: '/contact',
					mobile: false
				},
				{
					text: 'Color Mixer',
					to: '/color-mixer',
					mobile: false
				},
				{
					text: 'Color Blends',
					to: '/color-blends',
					mobile: false
				},
        {
          text: 'Architects',
          to: '/architects',
          mobile: false
        }
			],
			moreIsShown: false,
			width: 0
		}
	},

	created() {
		window.addEventListener('resize', this.updateWidth)
		this.updateWidth()
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.updateWidth)
	},

	methods: {
		updateWidth() {
			this.width = window.innerWidth
		}
	}

}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'


.header
	height: 9.2rem
	width: 100%

	padding: .9rem

	opacity: .9
	background: $color_white

	box-shadow: 0 5px 8px rgba(0, 0, 0, .08)

	align-items: stretch


	@media screen and (max-width: $tablet)
		height: unset


	&__logo-container
		flex-basis: 20%


		@media screen and (max-width: $small_desktop)
			display: none
			flex-basis: 0


	&__logo
		position: relative
		top: -.8rem


	&__navigation-container
		flex-basis: 80%


		@media screen and (max-width: $small_desktop)
			flex-basis: 100%
			justify-content: space-around


	&__navigation
		width: 80%


		@media screen and (max-width: $tablet)
			width: 100%


		&-list
			width: 100%


			@media screen and (max-width: $tablet)
				min-height: 5rem


			&--only-mobile


				@media screen and (max-width: $tablet)
					padding: 0 2%


			&-item
				display: inline-block


				&--only-mobile
					display: none


					@media screen and (max-width: $tablet)
						display: inline-block


</style>
