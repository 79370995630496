<template lang="pug">

	.spin(v-if="start")

		.ring

			<div class="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>

</template>

<script>
export default {
	name: 'SpinnerComponent',

	props: {
		start: {
			type: Boolean,
			default() {
				return false
			}
		}
	}

}
</script>

<style lang="scss" scoped>

.spin {
	z-index: 2;
	
	width: 100%;
	height: 100%;

	background: rgba(0, 0, 0, .2);

	position: fixed;

	top: 0;
}

.ring {
	position: absolute;

	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);

	margin: 0 auto;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 4px;
  border: 4px solid rgb(255, 255, 255);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgba(255, 255, 255, 1) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.2s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.55s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.01s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>