import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingView from '../views/landing/LandingView.vue'

function resolveComponents (components) {
	return Promise.all(components.map( component => {
		return typeof component === 'function' ? component() : component
	}))
}

async function beforeEach (to, from, next) {

	const components = await resolveComponents(
		router.getMatchedComponents({ ...to })
	)
	
	if (components.length === 0) {
		return next()
	}
	
	router.app.setLayout(components[0].default ? components[0].default.layout : (components[0].layout) || '')

	next()
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'LandingView',
		component: LandingView
	},
  {
		path: "*",
		redirect: { name: 'ContactView' }
	},
	{
		path: '/products',
		name: 'ProductsView',
		component: () => import('../views/pages/ProductsView.vue')
	},
	{
		path: '/services',
		name: 'ServicesView',
		component: () => import('../views/pages/ServicesView.vue')
	},
	{
		path: '/contact',
		name: 'ContactView',
		component: () => import('../views/pages/ContactView.vue')
	},
	{
		path: '/color-mixer',
		name: 'ColorMixerView',
		component: () => import('../views/pages/ColorMixerView.vue')
	},
	{
		path: '/color-blends',
		name: 'ColorBlendsView',
		component: () => import('../views/pages/ColorBlendsView.vue')
	},
	{
		path: '/architects',
		name: 'ArchitectsView',
		component: () => import('../views/pages/ArchitectsView.vue')
	}
]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach(beforeEach)

export default router
