<template lang="pug">

	.landing

		section.landing__section.landing__section--main

			img.landing__img.landing__img--main(src="~@/assets/img/main_section_back.jpg")

			.landing__container.landing__container--main(class="container u-h100")

				.landing__content

					h1.landing__title(class="primary-title") You don’t have to look any further for a Surfacing Company.

					p.landing__sub-title(class="sub-title") Create your own flooring designs from Titus Surfacing or try our color mixer

					button.landing__button.landing__button--main(class="btn btn--default" @click="$router.push('/contact')") Contact us


		section.landing__section.landing__section--services(class="u-df-center")

			img.landing__img.landing__img--services(src="~@/assets/img/services_section_back.svg")

			.landing__container.landing__container--services(class="container u-df-between")

				.landing__services-video-box

					.landing__services-video-wrapper(class="u-df-center")

						.landing__services-play-button(:class="{ 'hidden' : playerIsPlaying }" @click="playVideo")
					
						youtube.landing__services-video(:video-id="videoId" @ready="ready" :player-width="youTubeWidth" :player-height="youTubeHeight")

				.landing__services-description-box

					p.landing__services-sub-title(class="sub-title") Our services

					h2.landing__services-title(class="secondary-title") We offer a wide range of services to help you from concept to completion.

					ul.landing__services-list

						li.landing__services-list-item(v-for="(item, index) in services" :key="index") {{ item }}

					button.landing__button.landing__button--services(class="btn btn--default" @click="$router.push('/services')") More


		section.landing__section.landing__section--cards(class="u-df-center")

			img.landing__img.landing__img--cards--1(src="~@/assets/img/cards_section_back_1.svg")
			img.landing__img.landing__img--cards--2(src="~@/assets/img/cards_section_back_2.svg")
		
			.landing__container.landing__container--cards(class="container u-df-between")

				.landing__card-box
					.landing__card(class="u-df-column-flex-start")
						h3.landing__card-title(class="article-title") The Astonishing Power of Eye Catching Surfacing
						p.landing__card-content Get the Surfacing you desire at our highly competitive pricing

				.landing__card-box
					.landing__card(class="u-df-column-flex-start")
						h3.landing__card-title(class="article-title") Turn-Key Services
						p.landing__card-content From excavation, to subbase, to surfacing, we can provide it all with our Professional Installation Teams


		section.landing__section.landing__section--contact(class="u-df-center")

			img.landing__img.landing__img--contact(src="~@/assets/img/contact_section_back.jpg")
		
			.landing__container.landing__container--contact(class="container u-df-between")
				BaseContactFormComponent

</template>

<script>
export default {
	name: 'LandingView',

	created() {
		window.addEventListener('resize', this.updateWidth)
		this.updateWidth()
	},

	computed: {
		playerIsPlaying() {
			return this.isPlaying
		}
	},

	data() {
		return {
			player: null,
			isPlaying: false,
			youTubeWidth: '686',
			youTubeHeight: '386',
			videoId: 'ujn5XZiVioc',
			services: [
				'Design assistance',
				'Estimating',
				'Professional Turnkey Installation',
				'Project Management from start to finish',
				'Maintenance'
			]
		}
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.updateWidth)
		this.player.removeEventListener('onStateChange', this.showPlayButton)
	},

	methods: {
		updateWidth() {
			let width = window.innerWidth

			if (width < 1537) {

				if (width > 576) {
					this.youTubeWidth = '580'
					this.youTubeHeight = '327'
				} else {
					this.youTubeWidth = '318'
					this.youTubeHeight = '179'
				}

			} else if (width > 1536) {
				this.youTubeWidth = '686'
				this.youTubeHeight = '386'
			}
		},
		playVideo() {
			if (this.player) {
				this.player.playVideo()
			}
		},
		showPlayButton(e) {			
			let playerState = e.target.getPlayerState()
			if (playerState === 2 || playerState === 0) this.isPlaying = false
			else if (playerState === -1 || playerState === 1) this.isPlaying = true
		},
		ready(event) {
			this.player = event.target
			this.player.addEventListener( 'onStateChange', this.showPlayButton )
		},
		stop() {
			this.player.stopVideo()
		}
	}

}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'


.hidden
	display: none


.landing
	
	
	&__section


		&--main, &--cards
			min-height: 100vh
			position: relative


			@media screen and (max-width: $phone)
				min-height: 81rem


		&--services
			margin: 12rem 0 2rem
			position: relative


			@media screen and (max-width: $phone)
				margin-top: 0
			

		&--contact
			position: relative
			margin-bottom: 15rem


			@media screen and (max-width: $phone)
				margin-bottom: 0
	
	
	&__img


		&--main
			object-fit: contain
			object-position: top

			position: absolute
			
			top: 0
			right: 0
			
			height: 93rem

			max-height: 95vh
			max-width: 61%


			@media screen and (max-width: $phone)
				max-height: 31rem
				max-width: 101%


		&--services
			object-fit: contain
			object-position: left

			position: absolute
			
			top: -8.7rem
			left: 0

			height: 129%
			max-width: 35%

			z-index: -1


			@media screen and (max-width: $phone)
				top: -25rem
				max-width: 99.7%


		&--cards--1
			object-fit: contain
			object-position: left

			position: absolute
			
			bottom: -38rem
			left: 0

			height: 97%
			max-width: 35%

			z-index: -1


			@media screen and (max-width: $desktop)
				height: 112%


			@media screen and (max-width: $phone)
				bottom: -46rem
				max-width: 93%


		&--cards--2
			object-fit: contain
			object-position: right

			position: absolute
			
			top: 9rem
			right: 0

			height: 63%
			max-width: 35%

			z-index: -1


			@media screen and (max-width: $phone)
				top: -10rem
				max-width: 99%


		&--contact
			object-fit: contain
			object-position: right

			position: absolute
			
			top: -4rem
			right: 0

			height: 120%
			max-width: 50%

			z-index: -1


			@media screen and (max-width: $phone)
				top: 30rem
				max-width: 80%


	&__container


		&--services


			@media screen and (max-width: $small_desktop)
				flex-direction: column


		&--cards


			@media screen and (max-width: $small_desktop)
				flex-direction: column


		&--contact


			@media screen and (max-width: $tablet)
				display: flex
				align-items: center
				justify-content: center


	&__content
		position: absolute

		top: 33vh


		@media screen and (max-width: $phone)
			width: 89%
			top: 32rem

			text-align: center


	&__title
		width: 57.9rem
		margin-bottom: 4rem


		@media screen and (max-width: $phone)
			width: 100%


	&__sub-title
		width: 42.5rem
		margin-bottom: 8rem


		@media screen and (max-width: $phone)
			width: 100%
			margin-bottom: 5rem


	&__button


		&--main, &--services
			display: block

			width: 26.3rem

			
			@media screen and (max-width: $phone)
				display: inline-block


	&__services


		&-video-wrapper
			width: 68.6rem
			height: 38.6rem

			border-radius: 5rem

			flex-shrink: 0
			overflow: hidden

			position: relative


			@media screen and (max-width: $desktop)
				width: 58rem
				height: 32.7rem

				border-radius: 4.5rem


			@media screen and (max-width: $phone)
				width: 31.8rem
				height: 17.9rem

				border-radius: 2.5rem


		&-play-button
			background: url(~@/assets/img/play_button.svg) no-repeat center / contain

			width: 9.9rem
			height: 9.9rem

			border-radius: 50%

			position: absolute

			top: 50%
			left: 50%

			transform: translate(-50%, -41%)

			cursor: pointer


			@media screen and (max-width: $desktop)

				width: 8rem
				height: 8rem


		&-video
			max-height: 100%
			max-width: 100%


		&-description-box


			@media screen and (max-width: $desktop)
				margin-top: 5rem
				text-align: center


		&-title
			width: 56.7rem
			margin: 3.2rem 0


			@media screen and (max-width: $phone)
				width: 100%


		&-list
			padding: 0 3rem
			text-align: left

			margin-bottom: 6rem


			&-item
				display: block
				position: relative
				
				
				&:not(:last-child)
					margin-bottom: 2.8rem


				&::before
					content: ""
					display: inline-block

					background: $color_yellow

					height: 1.3rem
					width: 1.3rem

					border-radius: 50%

					position: absolute

					top: 50%
					left: -2.8rem

					transform: translate(0, -44%)


	&__card
		box-shadow: 0px 5px 10px rgba($color_black_light, .15)
		border-radius: 5rem

		padding: 5rem

		height: 25.7rem
		width: 54.6rem

		cursor: default

		position: relative

		background: $color_white


		@media screen and (max-width: $phone)
			width: 85%
			height: unset

			padding: 5rem 4.6rem


		&-title


			@media screen and (max-width: $phone)
				margin-bottom: 2rem


		&::before
			content: ""
			display: inline-block

			background: url(~@/assets/img/ic_check.svg) no-repeat center / contain

			height: 4.8rem
			width: 4.8rem

			position: absolute

			top: -1.2rem
			left: -1.2rem

			box-shadow: 0px 5px 10px rgba($color_black_light, .15)

			border-radius: 50%


		&-box


			@media screen and (max-width: $phone)
				display: flex
				align-items: center
				justify-content: center
			
			
			&:not(:last-child)


				@media screen and (max-width: $small_desktop)
					margin-bottom: 5rem


</style>