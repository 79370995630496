export default {

	computed: {
		isSubmitDisabled: {
			get() {
				return Boolean(
					this.errors.errorNameRequired || this.errors.errorEmailRequired || this.errors.errorMessageRequired || this.btn.disabled
				)
			},
			set(newVal) {
				this.btn.disabled = newVal
			}
		},
		isNameDisabled() {
			return Boolean(
				this.errors.errorNameRequired
			)
		},
		isEmailDisabled() {
			return Boolean(
				this.errors.errorEmailRequired
			)
		},
		isMessageDisabled() {
			return Boolean(
				this.errors.errorMessageRequired
			)
		}
	},

	methods: {
		// validate block
		handleSubmit(e) {
			e.preventDefault()
			this.forceValidation()
			if (!this.isSubmitDisabled) {
				this.submit()
			}
		},
		forceValidation() {
			this.validateNameInput(this.name)
			this.validateEmailInput(this.email_or_phone)
			this.validateMessageInput(this.message)
		},
		handleNameInput(e) {
			this.name = e.target.value
			this.validateNameInput(this.name)
		},
		validateNameInput(value) {			
			this.errors.errorNameRequired = value.length === 0 ? 'This field is required' : ''
		},
		handleEmailInput(e) {
			this.email_or_phone = e.target.value.trim()
			this.validateEmailInput(this.email_or_phone)
		},
		validateEmailInput(value) {
			this.errors.errorEmailRequired = value.length === 0 ? 'This field is required' : ''
		},
		handleMessageInput(e) {
			this.message = e.target.value
			this.validateMessageInput(this.message)
		},
		validateMessageInput(value) {
			this.errors.errorMessageRequired = value.length === 0 ? 'This field is required' : ''
		},
		// end validate block
	}
}