export default {
	
	data() {
		return {
			errors: {
				errorNameRequired: '',
				errorEmailRequired: '',
				errorMessageRequired: ''
			}
		}
	}
	
}