<template lang="pug">

	.contact__box

		.contact(class="u-df-column-flex-start")

			p.contact__caption(class="caption-text" :class="{ 'mb' : captionIsNotDefault }" v-if="caption") {{ caption }}
			h2.contact__title(class="secondary-title") Contact us

			h3(class="article-title" :class="{ 'caption-text' : article }" v-if="article") {{ article }}

			form.contact__form(class="form" :class="{ 'mt' : !article }" @submit="handleSubmit($event)")
				
				label.contact__label(class="label" for="name") Name
					span *
					.error(v-show="isNameDisabled") {{ errors.errorNameRequired }}
				input.contact__input(class="input" id="name" :value="name" @input="handleNameInput($event)" @blur="handleNameInput($event)")
				
				label.contact__label(class="label" for="email_or_phone") Email or Phone
					span *
					.error(v-show="isEmailDisabled") {{ errors.errorEmailRequired }}
				input.contact__input(class="input" id="email_or_phone" :value="email_or_phone" @input="handleEmailInput($event)" @blur="handleEmailInput($event)")
				
				label.contact__label(class="label" for="message") Message
					span *
					.error(v-show="isMessageDisabled") {{ errors.errorMessageRequired }}
				textarea.contact__textarea(class="textarea" id="message" :value="message" @input="handleMessageInput($event)" @blur="handleMessageInput($event)")

				button.contact__button(class="btn btn--blue" :class="{ 'green' : emailsSent }" :disabled="isSubmitDisabled" type="submit") {{ btnTxt }}

	
</template>

<script>
import Validation from '@/mixins/Validation.js'
import Errors from '@/mixins/Errors.js'

import { mapActions } from 'vuex'

export default {
	name: 'BaseContactFormComponent',

	mixins: [ Validation, Errors ],

	props: {
		caption: {
			type: String,
			default() {
				return 'Don\'t wait any longer to'
			}
		},
		article: {
			type: String,
			default() {
				return 'about your Project!'
			}
		}
	},

	computed: {
		captionIsNotDefault() {
			return this.caption !== 'Don\'t wait any longer to'
		},
		
	},

	data() {
		return {
			name: '',
			email_or_phone: '',
			message: '',
			btnTxt: 'Send',
			emailsSent: false,
			btn: {
				disabled: false
			},
			inputs: []
		}
	},

	methods: {
		...mapActions([ 'sendFormRequest' ]),

		submit() {

			this.btnTxt = 'Sending...'

			this.isSubmitDisabled = true

			this.inputs.push(document.getElementById('name'))
			this.inputs.push(document.getElementById('email_or_phone'))
			this.inputs.push(document.getElementById('message'))

			this.inputs.forEach( item => item.setAttribute('disabled', true) )

			this.initDispatchForm()
		},

		initDispatchForm() {
			const data = {
				name: this.name,
				emailOrPhone: this.email_or_phone,
				message: this.message
			}

			this.sendFormRequest(data)
				.then( () => {
					this.emailsSent = true
					this.btnTxt = 'Sent'

					this.inputs.forEach( item => item.removeAttribute('disabled') )
					this.isSubmitDisabled = false
					
					this.name = ''
					this.email_or_phone = ''
					this.message = ''

					setTimeout( () => {
						this.emailsSent = false
						this.btnTxt = 'Send'
					}, 2000)
				})
				.catch( err => {
					console.log(err)
					this.btnTxt = 'Error... Try again...'
					this.inputs.forEach( item => item.removeAttribute('disabled') )
					this.isSubmitDisabled = false

					setTimeout( () => {
						this.btnTxt = 'Send'
					}, 2000)
				})
		}
	}
}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'
@import '~@/assets/styles/base/_animations.sass'


.green
	background-color: $color_green !important
	border-color: $color_green !important
	color: $color_black !important


.error
	display: inline-block
	color: $color_red
	font-size: 1.2rem

	margin-left: .5rem

	animation: shake .6s linear


.mb
	margin-bottom: 1.2rem


.mt
	margin-top: 1.2rem !important


.contact__box


	@media screen and (max-width: $phone)
		width: 85%


.contact
	box-shadow: 0px 5px 10px rgba($color_black_light, .15)
	border-radius: 5rem

	padding: 5rem

	width: 54.6rem

	cursor: default

	position: relative

	background: $color_white


	@media screen and (max-width: $phone)
		width: 100%
		padding: 5rem 4.6rem


	&__title
		margin-bottom: 1rem


		@media screen and (max-width: $phone)
			font-size: 3.4rem
			margin-bottom: .2rem


	&__form
		margin-top: 3.6rem


		& > *:nth-child(even):not(:last-child)
			margin-bottom: 2.8rem


	&__textarea
		height: 15.6rem


	&__button
		display: block

		width: 26.3rem


		@media screen and (max-width: $phone)
			width: 100%


</style>